@import './variables.scss';

.card {
  background-color: $white;
  border: 6px solid $white;
  display: inline-block;
  margin: 0 8px 16px;
  overflow-y: hidden;
  position: relative;
  transition-duration: 0.3s;
  transition-property: background-color;
  width: calc(33.333% - 16px);

  @include breakpoint-below($breakpoint-lg) {
    width: calc(50% - 16px);
  }

  @include breakpoint-below($breakpoint-md) {
    width: calc(100% - 16px);
  }

  &:hover {
    background-color: $teal6;
    transition-duration: 0.3s;
    transition-property: background-color;

    .card-image {
      opacity: 0.18;
      position: relative;
      transition-duration: 0.2s;
      transition-property: opacity;

      &::after {
        @include size(100%);

        background:
          -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(10%, #7fcbcd),
            color-stop(80%, rgba(127, 203, 205, 0))
          );
        background: linear-gradient(to top, #7fcbcd 10%, rgba(127, 203, 205, 0) 80%);
        bottom: 0;
        content: '';
        display: inline-block;
        position: absolute;
      }
    }
  }

  &:hover .card-info {
    background: transparent;
    color: $white;
  }

  &:hover .card-title,
  &:hover .card-subtitle,
  &:hover .card-description {
    color: $white;
  }

  .card-image {
    @include size(100%, 20vh);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    min-height: 180px;
    transition-duration: 0.3s;
    transition-property: opacity;
  }

  .card:hover .card__image::after {
    background:
      -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(10%, #7fcbcd),
        color-stop(80%, rgba(127, 203, 205, 0))
      );
    background: linear-gradient(to left, #7fcbcd 10%, rgba(127, 203, 205, 0) 80%);
  }

  .card-info {
    color: $lightbronze;
    padding: 25px 30px 70px;
    position: relative;
    width: 100%;
  }

  .card-title {
    font-family: monospase, sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .card-subtitle {
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }

  .card-header {
    margin-bottom: 24px;
  }

  .card-description {
    color: $black;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
}

.card-glossary {
  @extend .card;

  background-color: $lightbronze;
  border-color: $lightbronze;
  transition: all 0.2s;

  .card-title,
  .card-description {
    color: $white;
  }

  &:hover {
    border-color: $white;
    transition: all 0.2s;
  }
}

.card-clear {
  @extend .card;

  padding: 22px;
  background-color: white;
  overflow-y: auto;
  color: $black;

  &:hover {
    background-color: $white;
  }

  .card-clear-content h1 {
    font-size: 28px;
    line-height: normal;
    color: $black;
    text-transform: none;
    font-family: 'Roboto Mono', monospace;
    margin: 0;
    hyphens: auto;

    @include breakpoint(940) {
      font-size: 28px;
    }
  }

  .card-clear-content p {
    margin-bottom: 24px;
    font-size: 17px;
    line-height: 23px;
  }

  .card-clear-tags {
    color: $dust;
    font-weight: bold;
    margin-bottom: 16px;
    min-height: 26px;

    span {
      font-size: 12px;
      margin-right: 12px;
      text-transform: uppercase;
    }
  }
}

.card-material {
  @extend .card-clear;

  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .card-material-content {
    @include size(100%);

    background-color: $basegray;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
  }

  .card-material-block-text {
    bottom: 30px;
    // color: $lightbronze;
    left: 50%;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    text-align: center;
    width: 90%;
    text-transform: uppercase;
    transform: translateX(-50%);
  }

  .card-material-block-quantity {
    padding-right: 8px;
    font-weight: 700;
  }
}

.card-horizontal {
  background-color: $white;
  border: 6px solid $white;
  color: $dust;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 16px;
  position: relative;
  transition-duration: 0.3s;
  transition-property: background-color;
  width: 100%;

  .card-horizontal-label {
    background-color: $white;
    border-radius: 0;
    color: $lightbronze;
    font-size: 12px;
    font-weight: 600;
    left: 0;
    padding: 0 9px 2px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
  }

  .card-horizontal-number {
    background-color: $white;
    border-radius: 0;
    color: $lightbronze;
    font-size: 36px;
    font-weight: 600;
    left: 10px;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 55px;
    position: absolute;
    text-transform: uppercase;
    top: 10px;
  }

  .card-horizontal-image {
    background-position: center center;
    background-size: cover;
    transition-duration: 0.1s;
    transition-property: opacity;
    width: 50%;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      picture img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .card-horizontal-body {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    padding: 30px 30px 20px 36px;
    text-transform: none;
    width: 50%;
    flex: 1;
  }

  .card-horizontal-body h3 {
    color: $dust;
    font-size: 32px;
    font-weight: 400;
    margin: 10px 0 16px;
    text-transform: uppercase;
    line-height: 1;
  }

  .card-horizontal-arrow {
    @include size(50px);

    background-color: $white;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMCIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyNXB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAyNSAxOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjUgMTgiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGZpbGw9IiMwREExQTYiIGQ9Ik0yMi4yMjYsOC4yODRsLTYuMzcxLTYuMzQ2bC0xLjQyNCwxLjQxOGw0LjY4Miw0LjY0MUgyLjUzM3YxLjk5NGgxNi41NzlsLTQuNjgyLDQuNjRsMS40MjQsMS40MTkKCQlsNi4zNzEtNi4zNDdDMjIuNjM2LDkuMzE4LDIyLjYzNiw4LjY3LDIyLjIyNiw4LjI4NHoiLz4KPC9nPgo8L3N2Zz4K);
    background-position: center;
    background-repeat: no-repeat;
    bottom: 6px;
    display: inline-block;
    position: absolute;
    right: 6px;
  }

  .card-horizontal-cta {
    color: $teal1;
    font-size: 18px;
    margin: 15px 0 0;
    font-weight: 40;
    text-transform: uppercase;
  }

  .card-horizontal-description {
    color: $black;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &:hover {
    background-color: $teal0;
    color: $white;
    transition-duration: 0.3s;
    transition-property: background-color;
  }

  &:hover .card-horizontal-image {
    opacity: 0.18;
    position: relative;
    transition-duration: 0.2s;
    transition-property: opacity;
  }

  &:hover .card-horizontal-image::after {
    @include size(100%);

    background:
      -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(10%, #7fcbcd),
        color-stop(80%, rgba(127, 203, 205, 0))
      );
    background: linear-gradient(to left, #7fcbcd 10%, rgba(127, 203, 205, 0) 80%);
    content: '';
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover .card-horizontal-label {
    color: $teal0;
  }

  &:hover .card-horizontal-body h3,
  &:hover .card-horizontal-cta,
  &:hover .card-horizontal-description {
    color: $black;
  }
}
