@import '~arduino-sass/src/variables';

.step-block {
  position: relative;
  padding-bottom: 32px;

  img {
    background-color: #dee3e3;
  }
}

.step-block-item {
  display: none;
  // border: 1px solid $silver;

  &.active {
    display: block;
  }
}

.step-block-text {
  margin: 0;
  padding: 20px;
  background-color: #f7f9f9;
  font-size: 17px;
  border: 1px solid $silver;
}

.step-block-arrow {
  position: absolute;
  top: 305px;
  height: 55px;
  width: 55px;
  background-color: $white;
  border: 1px solid $silver;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  i {
    width: 23px;
  }

  &-left {
    @extend .step-block-arrow;

    left: 20px;
  }

  &-right {
    @extend .step-block-arrow;

    right: 20px;
  }
}

.step-block-index {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background-color: $lightbronze;
  color: $white;
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  border: 3px solid white;
}

.step-block-index-text {
  padding: 5px;
  display: inline-block;
  margin: 0;
  font-size: 17px;
}

.step-block-index-dropdown {
  border-top: 3px solid white;
  text-transform: uppercase;
  background-color: $lightbronze;
  color: $white;
  position: relative;
  bottom: 0;
}

.step-block-index-dropdown-item {
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: $lightbronze;
  }
}

.step-block-img {
  width: 100%;
  height: 380px;
  display: block;
  object-fit: contain;

  img {
    object-fit: contain;
    max-height: 100%;
  }
}

.step-block-icon-left {
  @extend .icon-arrow-left;
}

.step-block-icon-right {
  @extend .icon-arrow-right;
}

.step-block-index-icon {
  vertical-align: middle;
  display: inline-block;
  background-color: $lightbronze;
  height: 45px;
  width: 45px;
  padding: 16px;
  opacity: 0.5;
}

.step-block-fullscreen {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $silver;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @include breakpoint-below($breakpoint-lg) {
    display: none;
  }

  i {
    width: 24px;
  }

  &:hover {
    background-color: $silver;
  }
}

.step-block-close-fullscreen {
  @extend .step-block-fullscreen;

  top: 40px;
  right: 40px;
  z-index: 10;

  @include breakpoint-below($breakpoint-sm) {
    top: 20px;
    right: 20px;
  }
}

.step-block-nav {
  display: block;
  text-align: center;
  position: absolute;
  top: 385px;
  width: 100%;

  &__item {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid $asbestos;
    border-radius: 50%;
    background: #fff;
    margin-left: 6px;
    text-align: center;
    position: relative;
    cursor: pointer;

    &::before {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: $asbestos;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--active {
    &::before {
      content: '';
    }
  }
}
