.form {
  background-color: white;
  margin-top: 100px;
  margin-bottom: 100px;
  border-radius: 5px;
  border: 1px solid $silver;
}

.form-field {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.form-element {
  display: inline-block;
  width: 100%;
}

.form-label {
  display: block;
  font-size: 16px;
  color: black;
  margin-bottom: 12px;
}

.form-label-inline {
  @extend .form-label;

  display: inline-block;
}

.form-element-half {
  @extend .form-element;

  width: 48%;
}

.form-header {
  border-bottom: 1px solid $silver;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  margin: 60px 0;
  font-weight: 600;
  color: $lightbronze;
  font-size: 36px;
}

.form-body {
  padding: 60px;

  &:last-child {
    margin-bottom: 0;
  }
}

input,
select,
textarea {
  border: 1px solid $fog;
  border-radius: 6px;
  padding: 12px 18px;
  display: block;
  width: 100%;
  font-weight: 100;
  background-color: $white;
  -webkit-appearance: none;
  appearance: none;
  font-size: 18px;

  &:focus {
    outline: none;
    border-color: $silver;
  }

  &::placeholder {
    color: #bfc5c6;
  }
}

textarea {
  height: 250px;
  resize: vertical;
}

input[type='checkbox'] {
  margin-right: 12px;
}

select {
  cursor: pointer;
  color: #bfc5c6;

  &.selected {
    color: initial;
  }
}
