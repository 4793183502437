@media print {
  #header,
  #footer,
  #launcher,
  .sidebar,
  .main-content-image,
  .history-buttons,
  video,
  .step-block-fullscreen,
  .step-block-arrow-right,
  .step-block-arrow-left,
  .step-block-nav,
  .app .print-button,
  .zen-button {
    display: none;
  }

  .block-main-column {
    width: 100%;
    margin-left: 0;

    .section {
      &:nth-child(1) ~ .section {
        page-break-before: always;
      }

      .table-wrapper {
        overflow-x: initial;
      }

      table {
        page-break-before: always;
        font-size: 15px;
        border-spacing: 3px;

        th,
        td {
          padding: 5px;
        }
      }
    }
  }

  .block-page,
  .wrap,
  .main-content {
    max-width: 100%;
    padding: 0;
  }

  .app {
    background-color: #fff;
  }

  .step-block-item {
    display: block;
  }

  .step-block-img {
    height: 35vh;
  }

  .step-block img,
  .main-content-header {
    background: none;
  }

  .step-block-text {
    padding: 0;
    color: inherit;
    background: none;
    border: none;
  }

  .tag-list .tag {
    color: inherit;
    padding: 0;
    margin: 0;

    &:last-child {
      &::after {
        content: none;
      }
    }

    &::after {
      content: ', ';
    }

    &.knowledge {
      background: none;
    }
  }

  .main-content-header {
    flex-direction: row-reverse;
  }
}
