.main-content {
  background-color: #fff;
  margin-bottom: 16px;
  position: relative;
}

.main-content-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 22vh;
  max-height: 340px;
  min-height: 240px;
  position: relative;
  width: 100%;
  margin-top: 25px;
}

.main-content-header {
  background: #f7f9f9;
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include breakpoint-below($breakpoint-xl) {
    flex-direction: column-reverse;
  }
}

.main-content-intro {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 36px;
  flex: 1;

  @include breakpoint-below($breakpoint-xl) {
    border-left: none;
  }

  @include breakpoint-below($breakpoint-md) {
    padding: 15px;
  }
}

.main-content-intro h1 {
  margin: 0;
}
