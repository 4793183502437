@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/variables';

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.intro-popover {
  &__body {
    font-size: 12px;
    line-height: 1.7;
    font-weight: 400;
    padding: 20px 20px 0 20px;
  }

  h2 {
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 1;
  }

  p {
    margin: 0 0 10px;

    &:last-of-type {
      margin: 0;
    }
  }
}

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.introjs-showElement {
  z-index: 9999999 !important;
}

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative;
}

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative;
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: $white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.introjs-relativePosition {
  position: relative;
}

.introjs-helperLayer {
  box-shadow: none !important;
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border: none !important;
  border-radius: 0 !important;
  transition: all 0.3s ease-out;
}

.introjs-helperLayer * {
  box-sizing: content-box;
}

.introjs-helperLayer *::before {
  box-sizing: content-box;
}

.introjs-helperLayer *::after {
  box-sizing: content-box;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out;
}

.introjs-helperNumberLayer {
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.introjs-arrow {
  border: 12px solid transparent;
  content: '';
  position: absolute;
}

.introjs-arrow.top {
  top: -24px;
  left: 40px;
  border-bottom-color: $white;
}

.introjs-arrow.top-right {
  top: -24px;
  right: 10px;
  border-bottom-color: $white;
}

.introjs-arrow.top-middle {
  top: -24px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: $white;
}

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: $white;
}

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: $white;
}

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: $white;
}

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: $white;
}

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: $white;
}

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: $white;
}

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: $white;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: $white;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out;
}

.introjs-tooltiptext {
  padding: 20px;
}

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px;
}

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap;
}

.introjs-tooltipbuttons::after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-shadow: 1px 1px 0 $white;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  border-radius: 0.2em;
  zoom: 1;
  color: $teal3;
  font-weight: bold;
  border: none;
  background-color: $white;
  box-shadow: none;
  text-transform: uppercase;

  &:hover,
  &:focus {
    color: $teal4;
    border: none;
    background-color: $white;
    box-shadow: none;
  }
}

.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-nextbutton {
  float: right;
}

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-hidden {
  display: none;
}

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block;
}

.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}

.introjs-bullets ul li a {
  transition: width 0.1s ease-in;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.introjs-bullets ul li a:hover,
.introjs-bullets ul li a:focus {
  width: 15px;
  background: #999;
  text-decoration: none;
  outline: none;
}

.introjs-bullets ul li a.active {
  width: 15px;
  background: #999;
}

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}

.introjs-hint:focus {
  border: 0;
  outline: 0;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
}

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  animation: none;
}

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

.introjs-tooltip {
  min-width: 350px !important;
  max-width: 400px !important;
}

.into-modal__content {
  font-size: 15px;
  line-height: 1.6;

  p {
    margin: 0 0 15px;
  }
}

.introjs-overlay {
  background: $black;
  opacity: 0.6;
  top: 60px !important;
  top: var(--header-height) !important;
}

.introjs-skipbutton,
.introjs-prevbutton,
.introjs-tooltip-header {
  display: none !important;
}

.introjs-tooltiptext {
  padding: 0 !important;
}

.introjs-tooltipbuttons {
  border: none !important;
}

.profile-tooltip {
  margin-right: 10px;
}
