.align-center {
  text-align: center;
}

.space-top {
  margin-top: 42px;
}

.blocks-grid .block-wrapper .block-cover-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 20vh;
  min-height: 180px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 24px;
}

.video iframe,
.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 36px;
  line-height: 1.5;
  margin: 12px 0;
  text-transform: uppercase;
  color: $dust;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.fixed-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9f9;
  z-index: 4;
  border-top: 1px solid #ecf1f1;
  text-transform: uppercase;
  transition: bottom 0.2s;
}

.fixed-banner.upper {
  bottom: -100px;
  transition: bottom 0.2s;
}

.fixed-banner-text {
  color: $lightbronze;
  font-size: 18px;
  font-weight: bold;
  margin-right: 40px;
}

.search-bar-container {
  text-align: center;
  margin-bottom: 36px;
}

.search-bar {
  max-width: 725px;
  height: 50px;
  border: 0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: $white;
  padding: 0 24px;

  .icon-search {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  input[type='text'] {
    border: none;
  }
}

.opposite-items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 48px 0;

  i {
    height: 40px;
    width: 40px;
  }
}
