@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/popover';
@import './variables.scss';
@import './mixins.scss';

.history-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  &.next:not(.prev) {
    flex-direction: row-reverse;
  }

  @include breakpoint-below($breakpoint-lg) {
    padding-bottom: 75px;
  }
}

.history-button-back {
  width: 43px;
  height: 43px;
  cursor: pointer;
}

.history-button-next {
  font-size: 16px;
  text-transform: uppercase;
  color: $white;
  background: $teal3;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 600;

  &:hover {
    background: $teal2;
  }
}

.time-management {
  line-height: 1;

  &__title {
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  table {
    text-align: left;
    border-spacing: 0;
    font-size: 17px;

    th {
      border-bottom: 1px solid $smoke;
      font-size: 12px;
    }

    th,
    td {
      padding: 15px;

      &:not(:first-child) {
        border-left: 1px solid $smoke;
      }
    }

    td {
      background: rgba($lightbronze, 0.1);
    }

    tr {
      &.time-management--white {
        td {
          background: $white;
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid $smoke;
        }
      }
    }
  }
}
