.tag-section {
  background-color: #fff;
  border: 1px solid $lightbronze;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 22px 26px 5px;
}

h4 {
  font-size: 17px;
  line-height: 21px;
  margin: 0 0 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 22px;

  .tag {
    color: #000;
    font-size: 12px;
    margin: 0 8px 8px 0;
    font-weight: 400;
    padding: 4px 10px 4px 12px;
    text-transform: uppercase;
    border: 1px solid #c9d2d2;
    border-radius: 2px;

    a {
      color: white;
      font-weight: bold;
    }
  }

  .tag.skill {
    background-color: rgba(29, 160, 134, 0.8);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .tag.knowledge {
    background-color: #ecf1f1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}
