@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/variables';

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;

  &.open {
    display: block;
  }

  &__body {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: $white;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 1200px;
    width: 100%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow: auto;
    padding: 30px 50px;
    font-size: 16px;

    .modal--updates & {
      max-width: 650px;

      ol {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
  }

  &__backdrop {
    background-color: rgba(#434f54, 0.85);
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__buttons {
    text-align: right;
    padding-top: 30px;
  }

  &__container {
    line-height: 1.4;
  }

  &__description {
    a {
      font-weight: bold;
      color: $teal2;
    }
  }
}
