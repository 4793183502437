@import '~arduino-sass/src/variables';
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.module-header-title {
  display: flex;
  position: absolute;
  height: 80px;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint-below($breakpoint-md) {
    max-width: 100%;
    width: 100%;
  }
}

.module-description {
  font-size: 16px;
  max-width: 80%;
  margin: 0 auto 30px;

  @include breakpoint-below($breakpoint-xl) {
    margin-bottom: 15px;
  }

  @include breakpoint-below($breakpoint-md) {
    max-width: 90%;
  }
}

.module-pre-title,
.module-title {
  padding: 18px 25px;
  font-size: 42px;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;

  @include breakpoint-below($breakpoint-md) {
    white-space: normal;
  }

  @include breakpoint-below($breakpoint-sm) {
    font-size: 36px;
    padding: 15px 10px;
  }
}

.module-pre-title {
  background: white;
  color: $teal2;
  font-weight: bold;
}

.module-header {
  margin-bottom: 60px;
  text-align: center;

  @include breakpoint-below($breakpoint-sm) {
    margin-bottom: 30px;
  }
}

.module-title {
  color: $white;
  font-weight: 400;
  background-color: $teal2;

  &.simple {
    background-color: transparent;
    color: $dust;
    white-space: initial;
  }
}

.module-introduction {
  margin: 0 auto;
  max-width: 920px;
  padding: 48px 0;
  position: relative;
  text-align: left;

  @include breakpoint-below($breakpoint-sm) {
    padding: 30px 0 0;
  }

  &.module-introduction-empty {
    padding: 0;
  }

  a {
    color: $teal2;
    font-weight: bold;
  }
}

.module-header-content {
  position: relative;

  &-title {
    text-align: center;
    border-bottom: 1px solid;
    margin: 0 auto;
    max-width: 920px;
    padding-bottom: 10px;
    margin-bottom: 50px;

    + .module-introduction {
      padding-top: 0;
    }
  }
}

.module-header-image {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
}

.module-divider-wrapped {
  max-width: 60%;
  margin: 0 auto;
}

.module-body {
  position: relative;
}
