.section-divider {
  border-bottom: 1px solid rgba(158, 132, 109, 0.4);
  border-top: 1px solid rgba(158, 132, 109, 0.4);
  display: inline-block;
  margin: 20px 0 36px;
  text-align: center;
  width: 100%;
  overflow: hidden;

  h3 {
    font-weight: 400;
  }

  .section-divider-title {
    background-color: rgba(188, 172, 153, 0.15);
    border-left: 1px solid rgba(158, 132, 109, 0.4);
    border-right: 1px solid rgba(158, 132, 109, 0.4);
    color: $lightbronze;
    display: inline-block;
    font-size: 20px;
    margin: 0;
    min-width: 33.333%;
    padding: 12px 60px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .section-divider-number {
    font-size: 12px;
    margin-right: -30px;
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  @include breakpoint-below($breakpoint-md) {
    background-color: rgba(188, 172, 153, 0.15);
    border-left: 1px solid rgba(158, 132, 109, 0.4);
    border-right: 1px solid rgba(158, 132, 109, 0.4);

    .section-divider-title {
      background-color: transparent;
      border-left: none;
      border-right: none;
    }
  }
}
